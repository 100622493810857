
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  UFliterals,
  UFSessionStateColorEnum,
  UFSessionStateEnum,
} from "@/utils/literals";
import { ISequence } from "@/schemas/ISequence";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import UserSessionsList from "@/components/UserSessionsList.vue";
import Score from "@/components/feedback/components/Score.vue";
import DynamicScore from "@/components/feedback/components/DynamicScore.vue";
import clientCache from "@/utils/cacheUtils";
import DataExportDialog from "@/components/DataExportDialog.vue";
import { generateRandomString } from "@/utils";

@Component({
  name: "SequencePage",
  components: {
    DataExportDialog,
    DynamicScore,
    Score,
    UserSessionsList,
  },
  computed: {
    ...mapGetters("user", ["canEnterAdmin", "isResearcher", "isAdministrator"]),
    ...mapState("sequence", ["sequence"]),
  },

  methods: {
    ...mapActions("setting", ["fetchSettingScores", "fetchSettingSessions"]),
    ...mapMutations("sequence", {
      setSequence: "SET_SEQUENCE",
    }),
    watchSequence() {

        this.$watch('sequence', () => {
          this.sequence.settings.forEach((setting) => {
            this.groups_by_id[setting.id] = []

            this.onPanelChange(setting.id)

            if( window.localStorage )
            {
              if( !localStorage.getItem('firstLoad') )
              {
                localStorage['firstLoad'] = true;
                window.location.reload();
              }

            }
          })
        });

    },
  },
})
export default class SequencePage extends Vue {
  @Prop({ required: false, type: String })
  readonly id: string | undefined;
  @Prop({ required: false, type: String })
  readonly code: string | undefined;

  // @Watch('sequence')
  // onSequenceChanged(newVal, oldVal) {
  //     this.sequence.settings.forEach((setting) => {
  //       console.log(setting);
  //       this.onPanelChange(setting.id)
  //     })
  // }
  private finished = null;
  private onGoing = null;
  // private sequence: ISequence = {
  //   title: "",
  //   description: "",
  //   is_open: false,
  //   settings: [],
  // };
  groups: {
    users: string[];
    status: string;
    session_id: string;
    started_time: string;
  }[] = [];
  private canEnterAdmin: boolean | undefined;
  private sequenceCode = "";

  end_loading=false;

  groups_by_id : {activity: string; groups:{
      users: string[];
      status: string;
      session_id: string;
      started_time: string;
    }[];
  }[] = [];

  tab = null;
  tabs = ["status", "scores"];
  scores: {
    session_id: string;
    group_score: number;
    individual_scores: {
      user: string;
      score: number;
    }[];
  }[] = [];
  enabledSettings: string[] = [];

  created(): void {
    this.watchSequence()

    if (this.id) {
      this.$store.dispatch("sequence/fetchFullById", {
        id: this.id,
        next: (sequence: ISequence) => {
          this.setSequence(sequence);
        },
      });
    }
    if (this.code) {
      this.$store.dispatch("sequence/fetchFullByCode", {
        code: this.code,
        next: (sequence: ISequence) => {
          this.setSequence(sequence);
        },
      });
    }

  }

  mounted(): void {

    if (this.$route.params.toRefresh){
      setTimeout(function() {
        window.location.reload();
      }, 2000);
    }
  }





  isFinished(groups:[]): boolean{
    let res=true
    groups=Object.values(groups)
    if(!groups){
      return false
    }
    if (groups.length==0){
      return false
    }
    for (let i=0 ; i<groups.length ; i++){
      if(groups[i].status!= "finished"){
        res=false
      }
    }
    return res
  }


  get allEnabled(): boolean {
    return this.sequence
      ? this.sequence.settings.every((setting) => !!setting.opened_at)
      : false;
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  /**
   * Return user friendly status
   * @param status
   * @returns string
   */
  getUFStatus(status: string): string {
    return UFSessionStateEnum[status];
  }
  /**
   * Return status corresponding color
   * @param status
   * @returns string
   */
  getUFStatusColor(status: string): string {
    return UFSessionStateColorEnum[status];
  }

  updateSequence(sequenceCode: string): void {
    this.$store.dispatch("sequence/updateSequence", {
      sequence: { ...this.sequence, code: sequenceCode },
      next: (updated: ISequence) =>
        this.$store.commit("sequence/SET_SEQUENCE", updated),
    });
  }

  onPanelChange(settingId: string): void {
    this.fetchSettingSessions({
      settingId,
      next: (
        groups: {
          users: string[];
          status: string;
          session_id: string;
          started_time: string;
        }[]
      ) => {
        if(this.groups_by_id[settingId].length==0){
          this.groups_by_id[settingId] = {...groups};
        }
        this.groups = groups;
      },
    });
  }

  generateCode(): void {
    this.sequenceCode = generateRandomString(5);
  }

  onTabChange(key: string): void {
    let settingId = key.split("_")[0];
    if (key.includes("scores")) {
      this.fetchSettingScores({
        settingId,
        next: (
          scores: {
            session_id: string;
            group_score: number;
            individual_scores: { user: string; score: number }[];
          }[]
        ) => {
          this.scores = scores;
        },
      });
    }
    if (key.includes("status")) {
      this.fetchSettingSessions({
        settingId,
        next: (
          groups: {
            users: string[];
            status: string;
            session_id: string;
            started_time: string;
          }[]
        ) => {
          this.groups = groups;
        },
      });
    }
  }

  get notOpenYet(): boolean {
    return !this.sequence.code || !this.sequence.is_open;
  }

  openActivity(id: string): void {
    this.enabledSettings.push(id);
  }

  disableActivity(id: string): void {
    this.enabledSettings = this.enabledSettings.filter(
      (setting_id) => setting_id !== id
    );
  }

  onCheckChange(isChecked: boolean, setting_id: string): void {
    isChecked
      ? this.openActivity(setting_id)
      : this.disableActivity(setting_id);
  }

  openSelectedSettings(): void {
    clientCache.cache(
      `currentOpenedSettings:${this.sequence.id}`,
      JSON.stringify(this.enabledSettings)
    );
    this.end_loading=false
    this.$router.push({
      name: "sequenceRequests",
      params: {
        id: this.sequence.id,
        code: this.sequence.code,
      },
    });
  }

  get displaySettingOpener(): boolean {
    return (
      !!this.sequence.code &&
      this.canEnterAdmin &&
      this.$route.name === "sequence" &&
      !this.allEnabled
    );
  };
}
